export const hamburger = () => {
  // body要素を取得
  const body = document.querySelector('body');
  // ハンバーガーボタンを取得
  const hambButton = document.querySelector('.js-hambButton');
  // ハンバーガーメニューのリンクを取得
  const hambLinks = document.querySelectorAll('.js-hambPanel a');

  // ハンバーガーボタンをクリックしたらメニューを開閉する
  hambButton.addEventListener('click', () => {
    const hambStatus = body.getAttribute('data-hamb');
    if (hambStatus === 'close') {
      body.setAttribute('data-hamb', 'open');
    }
    if (hambStatus === 'open') {
      body.setAttribute('data-hamb', 'close');
    }
  });

  // ハンバーガーメニューのリンクをクリックしたらメニューを閉じる
  hambLinks.forEach((link) => {
    link.addEventListener('click', () => {
      body.setAttribute('data-hamb', 'close');
    });
  });

  // 画面サイズが1200px以上になったらメニューを閉じる
  const mediaQuery = window.matchMedia('(max-width: 1200px)');
  window.addEventListener('resize', () => {
    if (!mediaQuery.matches) {
      body.setAttribute('data-hamb', 'close');
    }
  });

};